.BannerForLaunch_bannerForLaunch__Rdgl8 {

    --tw-bg-opacity: 1;

    background-color: rgb(51 65 85 / var(--tw-bg-opacity));

    padding: 1rem;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.BannerForLaunch_content__4R_kE {

    margin-left: auto;

    margin-right: auto;

    display: flex;

    max-width: 28rem;

    align-items: center;

    gap: 1rem;

    text-align: center
}

.PageForMarketing_pageForMarketing__cNZeu {

}

.PageWithTailwind_pageWithTailwind__jwJ4F {
  --main-theme-color: #4f46e5;
  --button-color-primary: #6366f1;
  --button-color-hover: #818cf8;
  --button-text-color: #fff;
  --button-color-secondary: #3b82f6;
  --button-color-secondary-hover: #60a5fa;
  --button-text-color-secondary: #fff;
  --button-color-tertiary: transparent;
  --button-color-tertiary-hover: #f3f4f6;
  --button-text-color-tertiary: #374151;
  min-height: 100vh}

.BlotterLogo_blotterLogo__d_RGW {
  display: flex;
  align-items: center;
}

.BlotterLogo_icon__OY8YM {
  box-shadow: #fff7 0 0 10px;
  border-radius: 32px;
}

.BlotterLogo_text__rbwdB {
  font-family: 'Josefin Sans';
  /* line-height: 1 !important; */
  /* height: 22px; */
  /* height: 36px; */
  text-shadow: #fff7 0 0 10px;
  margin-left: 0.5rem;
  font-weight: 700;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(61 64 92 / var(--tw-text-opacity))}

/* @value colorOrange: #ed9f47; */
/* @value colorOrangeBackground: hsl(32deg, 82%, 80%); */
/* Gray text cannot be lighter than this on a white background without comprimising accessibility */

.twoCirclesSpinner_twoCirclesContainer__91GYa {
  display: flex;
  flex: 0 1 100%;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.twoCirclesSpinner_label__zmORg {
    margin-left: 12px;
    --tw-text-opacity: 1;
    color: rgb(53 56 95 / var(--tw-text-opacity))}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
 .twoCirclesSpinner_twoCircles__7VAA4,
 .twoCirclesSpinner_twoCircles__7VAA4 > div {
     position: relative;
     box-sizing: border-box;
 }
 .twoCirclesSpinner_twoCircles__7VAA4 {
     display: block;
     font-size: 0;
     color: #f00;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-dark__nl6vp {
     color: #333;
 }
 .twoCirclesSpinner_twoCircles__7VAA4 > div {
     display: inline-block;
     float: none;
     background-color: currentColor;
     border: 0 solid currentColor;
 }
 .twoCirclesSpinner_twoCircles__7VAA4 {
     width: 32px;
     height: 32px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4 > div {
     position: absolute;
     top: 50%;
     left: 50%;
     background: transparent;
     border-style: solid;
     border-width: 2px;
     border-radius: 100%;
     -webkit-animation: twoCirclesSpinner_ball-clip-rotate-multiple-rotate__CSoH2 1s ease-in-out infinite;
             animation: twoCirclesSpinner_ball-clip-rotate-multiple-rotate__CSoH2 1s ease-in-out infinite;
 }
 .twoCirclesSpinner_twoCircles__7VAA4 > div:first-child {
     position: absolute;
     width: 32px;
     height: 32px;
     border-right-color: transparent;
     border-left-color: transparent;
 }
 .twoCirclesSpinner_twoCircles__7VAA4 > div:last-child {
     width: 16px;
     height: 16px;
     border-top-color: transparent;
     border-bottom-color: transparent;
     -webkit-animation-duration: .5s;
             animation-duration: .5s;
     -webkit-animation-direction: reverse;
             animation-direction: reverse;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-sm__mT7_y {
     width: 16px;
     height: 16px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-sm__mT7_y > div {
     border-width: 1px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-sm__mT7_y > div:first-child {
     width: 16px;
     height: 16px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-sm__mT7_y > div:last-child {
     width: 8px;
     height: 8px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-2x__FgiJM {
     width: 64px;
     height: 64px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-2x__FgiJM > div {
     border-width: 4px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-2x__FgiJM > div:first-child {
     width: 64px;
     height: 64px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-2x__FgiJM > div:last-child {
     width: 32px;
     height: 32px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-3x__ov6BJ {
     width: 96px;
     height: 96px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-3x__ov6BJ > div {
     border-width: 6px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-3x__ov6BJ > div:first-child {
     width: 96px;
     height: 96px;
 }
 .twoCirclesSpinner_twoCircles__7VAA4.twoCirclesSpinner_la-3x__ov6BJ > div:last-child {
     width: 48px;
     height: 48px;
 }
 /*
  * Animation
  */
 @-webkit-keyframes twoCirclesSpinner_ball-clip-rotate-multiple-rotate__CSoH2 {
     0% {
         transform: translate(-50%, -50%) rotate(0deg);
     }
     50% {
         transform: translate(-50%, -50%) rotate(180deg);
     }
     100% {
         transform: translate(-50%, -50%) rotate(360deg);
     }
 }
 @keyframes twoCirclesSpinner_ball-clip-rotate-multiple-rotate__CSoH2 {
     0% {
         transform: translate(-50%, -50%) rotate(0deg);
     }
     50% {
         transform: translate(-50%, -50%) rotate(180deg);
     }
     100% {
         transform: translate(-50%, -50%) rotate(360deg);
     }
 }

.clickable_clickable__oidZk {
  cursor: pointer;
}

.TwitterLogin_twitterLogin__6EQ1r {

}

.LoginView_loginView__Xu9HX {

    display: flex;

    height: 100vh;

    flex-direction: column
}

@media (min-width: 640px) {

    .LoginView_loginView__Xu9HX {

        flex-direction: row
    }
}

.LoginView_loginPanel__GPncp {

    display: flex;

    flex: 1 1 0%;

    flex-direction: column;

    justify-content: center;

    padding-top: 3rem;

    padding-bottom: 3rem;

    padding-left: 1rem;

    padding-right: 1rem
}

@media (min-width: 640px) {

    .LoginView_loginPanel__GPncp {

        padding-left: 1.5rem;

        padding-right: 1.5rem
    }
}

@media (min-width: 1024px) {

    .LoginView_loginPanel__GPncp {

        flex: none
    }

    .LoginView_loginPanel__GPncp {

        padding-left: 5rem;

        padding-right: 5rem
    }
}

@media (min-width: 1280px) {

    .LoginView_loginPanel__GPncp {

        padding-left: 6rem;

        padding-right: 6rem
    }
}

.LoginView_loginForm__qn5o6 {

    margin-left: auto;

    margin-right: auto;

    width: 100%;

    max-width: 24rem
}

@media (min-width: 1024px) {

    .LoginView_loginForm__qn5o6 {

        width: 24rem
    }
}

.LoginView_logo__tmlSD {

    display: flex;

    width: 100%;

    align-items: center;

    justify-content: center
}

.LoginView_signInTitle__1fBEg {

    margin-top: 1.5rem;

    text-align: center;

    font-size: 1.875rem;

    line-height: 2.25rem;

    font-weight: 800;

    --tw-text-opacity: 1;

    color: rgb(29 35 86 / var(--tw-text-opacity))
}

